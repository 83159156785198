.appendix {
  margin-left: 5px;
  font-weight: normal;
  color: lightgray;
}

#subRow {
  padding-left: 46px;
}

.btnCell {
  padding-left: 4px;
}

td.buttonGroupColumn {
  padding: 4px;
}

.buttonGroupAlignment {
  float: right;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #ccc;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.separator:not(:empty)::before {
  margin-right: 0.5em;
}

.separator:not(:empty)::after {
  margin-left: 0.5em;
}

.linkButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
