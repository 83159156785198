@use './styles/app' as *;

.menuValueItem {
  display: inline-block;
}

.menuItem {
  display: flex;
  justify-content: space-between;
  .menuCode {
    padding-left: 5px;
    align-content: center;
  }
}

.companyName {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 250px;
}

.header {
  display: block;
  color: rgba($color: white, $alpha: 0.6);
  text-transform: uppercase;
  font-size: 12px;
}

.navBlock {
  padding-top: 6px;
}

.activeLogin {
  background-color: var(--fz-navbar-dropdown-color);
}

.activeLogin,
.deactiveLogin {
  height: 50px;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 5px;
}

.activeLogin > div > a,
.activeLogin > div > a:hover {
  color: var(--fz-navbar-dropdown-color);
}

.activeLogin > div > a:active {
  background-color: var(--fz-navbar-dropdown-color);
  color: white;
}
