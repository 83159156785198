@use './styles/colors' as *;

:root {
  --fz-navbar-background-color: #{$purple-dark};
  --fz-navbar-dropdown-color: #{$primary};
}

.toggle {
  position: absolute !important;
  top: 0;
  right: 0;
  margin: 5px;
}

@media (min-width: 768px) {
  .navbar-inverse .navbar-nav > .show,
  .navbar-inverse .navbar-nav > .active,
  .navbar-inverse .navbar-nav > .show:focus,
  .navbar-inverse .navbar-nav > .show:hover {
    background-color: var(--fz-navbar-dropdown-color);
    color: #fff;
  }
}
.pullRight {
  margin-left: auto;
}

.allowWrap {
  flex-wrap: wrap;
}

.navBar {
  background-color: var(--fz-navbar-background-color);
  border-bottom: 10px solid;
  border-color: var(--fz-navbar-dropdown-color);
  margin-bottom: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.customLogo,
.mainLogo {
  display: inline-block;
  height: 100%;
  overflow: hidden;
  text-indent: -999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  list-style: none;
}

.mainLogo {
  background: url('./finazilla-logo-small.png');
  width: 22px;
}

.customLogo {
  height: 100%;
  width: 38px;
  margin-right: -1rem;
  background-size: cover;
  background-position: center;
}

.logoContainer {
  height: 50px;
  padding-top: 4px;
  padding-left: 15px;
}

.leftSideItem {
  display: flex;
  flex-direction: column-reverse;
  min-height: 50px;
  padding-bottom: 5px !important;
  white-space: nowrap;
}

.active {
  background-color: var(--fz-navbar-dropdown-color) !important;
}

/* TODO: NOT LIKE THIS */
.pullRight > div > a {
  padding: 0px;
}

.linksDropdown > div {
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  border: 1px solid var(--fz-navbar-dropdown-color);
  border-radius: 2px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-clip: padding-box;
  margin: 0px !important;
}

.linksDropdown > div > a,
.linksDropdown > div > a:hover {
  padding-top: 0px;
  color: var(--fz-navbar-dropdown-color);
}

.linksDropdown > div > a:active {
  background-color: var(--fz-navbar-dropdown-color);
  color: white;
}

.linksDropdown {
  display: flex;
  flex-direction: column-reverse;
  min-height: 50px;
  padding-bottom: 5px !important;
}

.linksDropdown > a {
  padding-bottom: 0px !important;
}

.linksDropdown a::after {
  margin-left: 6px;
  vertical-align: middle;
}
