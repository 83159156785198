@use 'styles/colors' as *;

.formField:disabled {
  cursor: not-allowed;
}

.booleanCheckBox {
  width: 20px;
  height: 20px;
  margin-bottom: -15px;
}

.radio-container {
  margin-bottom: 10px;
}

.inputGroupAddon {
  position: relative;
  display: table-cell;
  border-collapse: separate;
  //padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #4a4a4a;
  text-align: center;
  background-color: #ffffff;
  //border: 1px solid #979797;
  border-radius: 2px;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.formGeneric {
  flex-grow: 1;
}

.displayNone {
  display: none !important;
}

.infoIcon {
  margin-left: 5px;
}

.validationError {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: $input-helper-text;
}
