.header {
  display: block;
  color: rgba($color: white, $alpha: 0.6);
  text-transform: uppercase;
  font-size: 12px;
}

.activeLogin {
  background-color: var(--fz-navbar-dropdown-color);
}

.activeLogin,
.deactiveLogin {
  height: 50px;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 5px;
}

.activeLogin > div > a,
.activeLogin > div > a:hover {
  color: var(--fz-navbar-dropdown-color);
}

.activeLogin > div > a:active {
  background-color: var(--fz-navbar-dropdown-color);
  color: white;
}

.user-info {
  white-space: normal;
}
