@use './colors' as *;

//Buttons These should be done with scsss above. Can't figure out the syntax ~Lauri
.btn-xsmall,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

// button styling
.btn-primary {
  color: white;
  border-color: $primary-border;
}
.btn-primary:hover {
  color: white;
  background-color: $primary-hover;
  border-color: $primary-border;
}
.btn-primary:focus,
.btn-primary:active {
  color: white;
  background-color: $primary-hover;
  border-color: $primary-border;
}
.btn-primary:disabled {
  color: white;
  background-color: $primary-disabled;
  border-color: $primary-border;
}

.btn-success {
  color: white;
  border-color: $success-border;
}
.btn-success:hover {
  color: white;
  background-color: $success-hover;
  border-color: $success-border;
}
.btn-success:focus,
.btn-success:active {
  color: white;
  background-color: $success-hover;
  border-color: $success-border;
}
.btn-success:disabled {
  color: white;
  background-color: $success-disabled;
  border-color: $success-border;
}

.btn-warning {
  color: white;
  background-color: $warning;
  border-color: $warning-border;
}
.btn-warning:hover {
  color: white;
  background-color: $warning-hover;
  border-color: $warning-border;
}
.btn-warning:focus,
.btn-warning:active {
  color: white;
  background-color: $warning-hover;
  border-color: $warning-border;
}
.btn-warning:disabled {
  color: white;
  background-color: $warning-disabled;
  border-color: $warning-border;
}

.btn-default {
  color: $neutral;
  background-color: $secondary;
  border-color: $input-border;
}
.btn-default:hover {
  color: $neutral;
  background-color: $secondary-hover;
  border-color: $input-border;
}
.btn-default:focus,
.btn-default:active {
  color: $neutral;
  background-color: $secondary-hover;
  border-color: $secondary-active-border;
}
.btn-default:disabled {
  color: $neutral;
  background-color: $secondary-disabled;
  border-color: $secondary-border;
}

.btn-danger {
  color: white;
  background-color: $danger;
  border-color: $danger-border-color;
}
.btn-danger:hover {
  color: white;
  background-color: $danger-hover;
  border-color: $danger-border-color;
}
.btn-danger:focus,
.btn-danger:active {
  color: white;
  background-color: $danger-hover;
  border-color: $danger-border-color;
}
.btn-danger:disabled {
  color: white;
  background-color: $danger-disabled;
  border-color: $danger-border-color;
}
