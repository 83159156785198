// Base theme colors
$primary: #774b98;
$primary-hover: #654081;
$primary-disabled: #774b98a5;
$primary-border: #8b66a7;
$primary-rgb: 119, 75, 152;

$secondary: white;
$secondary-hover: #e6e6e6;
$secondary-disabled: #ffffffa5;
$secondary-border: #979797;
$secondary-active-border: #cccccc;
$secondary-border: $secondary-active-border;

// Ei tyylioppaassa
$purple-dark: #3d234f;

// $success: #8bc34a; Old
$success: #69cc93;
$success-hover: #4cc27e;
$success-disabled: #69cc93a7;
$success-border: #86c695;

$danger: #f44336;
$danger-hover: #f22112;
$danger-disabled: #f44336a7;
$danger-border-color: #e08d95;

$warning: #f0ad4e;
$warning-hover: #e0a800;
$warning-disabled: #ffd75ea5;
$warning-border: #f2d376;

$info: #00a99d;
$info-hover: #008379;
$info-disabled: #00a99da7;

// Extra greys
$light: #f8f9fa;
$light-hover: #e2e6ea;
$light-disabled: #fafcfca5;

$dark: #333333;
$dark-hover: #202020;
$dark-disabled: #333333a7;

$neutral: #4a4a4a;
$neutral-hover: #373737;
$neutral-disabled: #4a4a4aa5;

$gray-900: #212529;

$input-border: $secondary-border;
$input-placeholder: #c8c8c8;
$input-helper-text: $danger-disabled;

//Carets
$active: $neutral;
$deactive: $secondary-border;

//Forms
$background-color: #f2f2f2;
$hint-color: #ccc;

//Match color
$match-color: #ff0;

//Search Bar
$searchbar-border: #ccc;
$searchbar-match: #999;
$searchbar-background: #fff;
$searchbar-icon-inactive: #666;

// Dashboar filter
$filter-background-color: rgb(77, 77, 84); // dark theme
$dashboard-dark-button: rgba(64, 64, 64, 0.8);

// Global CSS Variables
:root {
  --fz-secondary-border: #{$secondary-border};
}
