.icon {
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.75);
}

.navigationContainer {
  height: 100%;
  width: 45px;
  display: flex;
  align-items: flex-end;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 10px;
}

.notificationsBlade {
  background-color: white;
  visibility: visible;
  position: absolute;
  /* width: 25%; */
  width: 600px;
  height: 80vh;
  top: 100%;
  margin-top: 10px;
  z-index: 999; /* Stay on top */
  right: 0px;
  overflow-x: hidden;
  transition: height 0.6s;
  flex: 1;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  padding: 10px;
}

.active {
  background-color: var(--fz-navbar-dropdown-color);
}

.bellIconContainer {
  margin-right: -10px;
}

.buttonGroup {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.time {
  margin-top: 5px;
  font-size: 0.9em;
  float: right;
}

.createButton {
  margin-top: 20px;
}

.actionButtons {
  display: flex;
  align-content: space-around;
  flex-direction: column;
  align-items: flex-end;
}

.description {
  white-space: pre-wrap;
  font-size: 0.9em;
}

.notification {
  padding-top: 5px;
  padding-bottom: 5px;
}

.notification:hover {
  background-color: #f5f5f5;
}

.justifyVerticalMiddle {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.severityIcon {
  margin-left: 10px;
}

.title {
  display: flex;
}

.titelHr {
  margin: 0px;
  border-top: 2px solid #dddddd;
  margin-bottom: 5px;
}

.titleRow {
  margin-top: -20px;
}

.notificationDot {
  position: relative;
  left: -10px;
  margin-right: -10px;
  font-size: 8px;
  top: 3px;
}

.primary {
  background-color: var(--fz-navbar-dropdown-color);
}

.notificationDotPending {
  display: inline-block;
  position: relative;
  left: -14px;
  margin-right: -10px;
}

.loadingDot {
  margin: 0px;
  width: 15px;
  height: 15px;
}

.loadingDot::before {
  background-color: white !important;
}
