:global(.dark) :global(.dashboard-container) .fontColor {
  color: white;
}

.sk-fading-circle {
  margin: 2em auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle-small {
  width: 20px;
  height: 20px;
  position: relative;
}

.skCircle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.skCircle::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #999;
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-circle2 {
  composes: skCircle;
  transform: rotate(30deg);
}

.sk-circle3 {
  composes: skCircle;
  transform: rotate(60deg);
}

.sk-circle4 {
  composes: skCircle;
  transform: rotate(90deg);
}

.sk-circle5 {
  composes: skCircle;
  transform: rotate(120deg);
}

.sk-circle6 {
  composes: skCircle;
  transform: rotate(150deg);
}

.sk-circle7 {
  composes: skCircle;
  transform: rotate(180deg);
}

.sk-circle8 {
  composes: skCircle;
  transform: rotate(210deg);
}

.sk-circle9 {
  composes: skCircle;
  transform: rotate(240deg);
}

.sk-circle10 {
  composes: skCircle;
  transform: rotate(270deg);
}

.sk-circle11 {
  composes: skCircle;
  transform: rotate(300deg);
}

.sk-circle12 {
  composes: skCircle;
  transform: rotate(330deg);
}

.sk-circle2::before {
  animation-delay: -1.1s;
}

.sk-circle3::before {
  animation-delay: -1s;
}

.sk-circle4::before {
  animation-delay: -0.9s;
}

.sk-circle5::before {
  animation-delay: -0.8s;
}

.sk-circle6::before {
  animation-delay: -0.7s;
}

.sk-circle7::before {
  animation-delay: -0.6s;
}

.sk-circle8::before {
  animation-delay: -0.5s;
}

.sk-circle9::before {
  animation-delay: -0.4s;
}

.sk-circle10::before {
  animation-delay: -0.3s;
}

.sk-circle11::before {
  animation-delay: -0.2s;
}

.sk-circle12::before {
  animation-delay: -0.1s;
}

.loadingMessage {
  text-align: center;
}

@keyframes sk-circleFadeDelay {
  0% {
    opacity: 0;
  }

  39% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
