@use 'styles/colors' as *;

.rightAligned {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

thead.tableHead {
  vertical-align: initial;
}

.leftAligned {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.labelItems {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
}

.caretActive {
  color: $active;
}

.caretDeactive {
  color: $deactive;
}

.rightAlignedWithNoCursor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.customSchemesDescription {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 111px;
}
