// @use './styles/variables.module' as *;
@use './styles/app' as *;

.pullRight {
  margin-left: auto;
}

.settingsTitleDropped div a,
.settingsTitleDropped a {
  color: var(--fz-navbar-dropdown-color) !important;
  padding: 3px 20px;
  padding-left: 20px !important;
}

.settingsTitleDropped a:hover {
  background-color: $navbar-dropdown-hover-background-color;
}

.settingsTitleDropped a::after {
  transform: rotate(-90deg);
}

.settingsTitleDropped a::after,
.settingsTitleInNav a::after {
  margin-left: 6px;
  vertical-align: middle;
}

.settingsTitleDropped > div {
  position: absolute !important;
  top: 0 !important;
  left: 100% !important;
}

.settingsTitleDropped > div,
.settingsTitleInNav > div {
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: $navbar-dropdown-background-color;
  border: 1px solid var(--fz-navbar-dropdown-color);
  border-radius: 2px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-clip: padding-box;
  margin: 0px !important;
}

.settingsTitleDropped > div > a,
.settingsTitleInNav > div > a {
  color: var(--fz-navbar-dropdown-color) !important;
  padding-top: 0px;
}

.settingsTitleDropped > div > a:active,
.settingsTitleInNav > div > a:active {
  background-color: var(--fz-navbar-dropdown-color);
  color: white !important;
}

.active {
  background-color: var(--fz-navbar-dropdown-color);
}

.settingsTitleInNav {
  display: flex;
  flex-direction: column-reverse;
  min-height: 50px;
  padding-bottom: 5px !important;
}

.settingsTitleInNav > a {
  padding-bottom: 0px !important;
}
