.dark {
  $border-color: rgba(255, 255, 255, 0.2);

  #frontpage,
  #dashboards {
    background: url(../images/finazilla_bg.jpg) repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // margin-top: -20px;
    flex: 1;

    #fm-pivot-view {
      border: none;
    }

    #fm-pivot-view .fm-grid-layout div.fm-cell {
      border-color: $border-color;
    }

    #fm-pivot-view,
    #fm-toolbar-wrapper #fm-toolbar,
    #fm-pivot-view .fm-grid-view,
    #fm-pivot-view .fm-charts-view,
    #fm-pivot-view .fm-grid-layout div.fm-cell,
    #fm-pivot-view .fm-grid-layout .fm-data-sheet,
    #fm-pivot-view .fm-grid-layout .fm-rows-sheet,
    #fm-pivot-view .fm-grid-layout .fm-cols-sheet,
    #fm-pivot-view .fm-grid-layout .fm-sheet-headers,
    #fm-pivot-view .fm-grid-layout .fm-filters,
    #fm-pivot-view div.fm-ui-modal-overlay {
      background-color: transparent;
    }

    #fm-pivot-view .fm-charts-view .fm-chart-legend .fm-chart-legend-container,
    #fm-pivot-view .fm-charts-view .fm-header-toolbar {
      background-color: rgba(48, 48, 48, 0.4);
    }

    #fm-pivot-view div.fm-ui-dropdown .fm-ui-dropdown-btn,
    #fm-toolbar-wrapper div.fm-ui-dropdown .fm-ui-dropdown-btn {
      background-color: rgba(64, 64, 64, 0.8);
    }

    .modifiedCell {
      background-color: rgba(64, 64, 64, 0.8) !important;
    }
  }

  #fm-pivot-view .fm-grid-layout .fm-cols-filter .fm-row,
  #fm-pivot-view .fm-grid-layout .fm-filters.fm-cols-filter .fm-filter-header,
  #fm-pivot-view .fm-grid-layout .fm-cols-filter,
  #fm-pivot-view .fm-grid-layout div.fm-cell.fm-empty {
    border: none;
  }

  #fm-pivot-view
    .fm-grid-layout
    .fm-cols-sheet
    .fm-scroll-content
    div.fm-row
    div[role='gridcell'] {
    border: none;
  }
  #fm-pivot-view .fm-grid-layout div.fm-cell.fm-header.fm-header-c.fm-empty {
    border-bottom: 1px solid $border-color;
  }
}
