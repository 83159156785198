.dimensionTool {
  position: relative;

  .close-all-button {
    position: absolute;
    right: 0px;
    margin: 6px;
    z-index: 3;
  }

  .isOpen {
    z-index: 4 !important;
  }
  .selectableDimension {
    font-weight: bold;
  }

  .rw-popup-container .rw-virtual-list-wrapper {
    height: 300px;
    max-height: 300px;
    overflow: auto;
  }

  .rw-popup-container .rw-virtual-list-wrapper .rw-virtual-list {
    max-height: 100%;
    overflow-y: hidden;
  }

  .customFilter .rw-popup-container .rw-popup {
    padding-top: 36px;
  }

  .customFilter .rw-popup-container .rw-virtual-list-wrapper .rw-virtual-list {
    overflow-y: hidden;
  }

  .search {
    z-index: 5;
    padding-left: 10px;
    margin: 3px;
  }

  .multi-select {
    z-index: 1;
    flex: 1;
  }

  .multi-select .rw-widget-input {
    padding-right: 32px;
  }

  @for $i from 0 through 9 {
    .level-#{$i} {
      padding-left: 10px * $i;
    }
  }
  @for $i from 0 through 12 {
    .color-#{$i} {
      margin-bottom: 2px;
    }
  }
  // Google Material Design Color Palette
  // https://material.google.com/style/color.html#color-color-palette
  .color-0 {
    color: #f44336;
  }
  .color-1 {
    color: #2196f3;
  }
  .color-2 {
    color: #8bc34a;
  }
  .color-3 {
    color: #ff9800;
  }
  .color-4 {
    color: #3f51b5;
  }
  .color-5 {
    color: #e91e63;
  }
  .color-6 {
    color: #03a9f4;
  }
  .color-7 {
    color: #ffc107;
  }
  .color-8 {
    color: #009688;
  }
  .color-9 {
    color: #ff5722;
  }
  .color-11 {
    color: #cddc39;
  }
  .color-10 {
    color: #9c27b0;
  }
  .color-12 {
    color: #ffeb3b;
  }
  .color-13 {
    color: #00bcd4;
  }
  .color-14 {
    color: #673ab7;
  }
  .color-15 {
    color: #4caf50;
  }
  .color-16 {
    color: #795548;
  }
  .color-17 {
    color: #9e9e9e;
  }
  .color-blank {
    color: #607d8b;
  }
}

.multiSelectCustomFilter {
  flex: 1;
  max-width: 200px;
  margin-right: 12px;
}

.notesLoadingListItem {
  float: right;
}

.notesLoadingListItem::before {
  content: none !important;
}

.notesLoadingInfo {
  margin-right: 20px;
  margin-bottom: 0px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
