/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 20px;
  left: 0px;
  top: 72px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #431868;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #431868;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#page-wrap {
  margin-left: 25px;
}