.btn-success {
  color: #fff;
  background-color: #8bc34a;
  border-color: #7eb73d;
}

.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f32c1e;
}

.btn:disabled {
  cursor: not-allowed;
  pointer-events: inherit !important; //Bootstrap sets pointer-events none to .btn:disabled
}
