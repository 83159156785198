.container {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.focus {
  border-color: #774b98;
  box-shadow: 0 0 8px rgba(119, 75, 152, 0.6),
    inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  outline: 0;
  padding: 0;
  height: 100%;
}

.item {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
  height: 2.4em;
}

.disabled {
  cursor: not-allowed;
  color: #ccc;
}

.item label,
.item input,
.item span {
  cursor: inherit;
}

.item input {
  margin-right: 0.75em;
}

.item label {
  display: block;
  font-weight: normal;
  margin: 0;
  width: 100%;
  padding: 0.143em 0.75em;
}

.item:hover {
  background-color: #f5f5f5;
}

.vertical {
  flex-direction: column;
}
