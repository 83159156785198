.developmentInfo {
  background-color: #999;
  color: #fff;
  padding: 5px 10px;

  span {
    text-transform: uppercase;
    padding-left: 57px;
  }
}
