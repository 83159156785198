/* stylelint-disable */
.successButtonWrapper {
  margin-top: 20px;
  float: right;
}

.table th {
  border-top: 0px;
}

.headerWrapper {
  h1,
  h4 {
    display: flex;
  }

  h4 {
    font-size: 18px;
    padding-top: 10px;
    margin-left: 0;
  }
}

.searchBarWrapper {
  margin-top: 20px;
  flex-grow: 1;
  margin-right: 15px;
}

.actionsAligned {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.secondaryButton {
  padding-right: 5px;
  margin-top: 20px;
}

.inlineBlock {
  display: inline-block;
}

.secondarySuccessButton {
  margin-right: 5px;
}

.splitMenu {
  padding: 2px 4px;
}

.splitButton {
  width: 100%;
  margin: 2px 0;
}
