@use 'styles/colors' as *;

.searchMatch {
  color: $searchbar-match;
  border: 0;
  padding-right: 12px;
  background-color: $searchbar-background;
}

.searchBar input {
  height: 32px;
  border: 0;
  box-shadow: unset !important;
}

.searchBar {
  flex-grow: 1;
  border: 1px solid $searchbar-border;
  border-radius: 2px;
  transition: box-shadow ease-in-out 0.15s;
}

.divider {
  border-right: 1px solid $searchbar-border;
  padding-left: 14px;
  height: 60%;
}

.icons button {
  border: 0;
}
.icons button[disabled],
.icons button[disabled]:hover {
  border-color: $searchbar-border;
  opacity: 1;
}

.icons button span {
  font-size: 0.8em;
  color: $searchbar-match;
}
.icons button:hover span {
  color: $searchbar-icon-inactive;
}
.icons button[disabled] span {
  color: $searchbar-border;
}
