@use 'styles/colors' as *;

button.iconStyles {
  border: none;
  background-color: transparent;
}

.deleteConfirmCheckbox {
  padding-top: 10px;
}

.scrollToTopButton {
  position: fixed;
  top: calc(50% - 70px);
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $primary;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}

.scrollToDownButton {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $primary;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}
