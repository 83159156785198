.login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background: url('./finazilla-login-bg.jpg') no-repeat;
  background-color: #774997;
  background-size: cover;
}
.login div.panel-default {
  border: none;
  width: 400px;
}
.login header.panel-heading {
  border: none;
  background-color: #774997;
  padding: 20px 35px 20px 0;
  text-align: center;
  width: 400px;
}

.login .float-right {
  float: right;
}

.login h3 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  font-weight: 300;
  color: #774997;
}

.login .invalid-field {
  border: 1px solid red;
}

.login form span.validation-error {
  color: red;
  margin-left: 14px;
  font-size: 12px;
}

.login .sk-fading-circle {
  margin: 0 auto;
  width: 20px;
  height: 20px;
}

.login a {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
.login .signUp {
  display: flex;
  margin-top: 10px;
}
.conversion-actions button {
  margin-top: 15px;
  width: 45%;
}

.conversion-actions {
  position: relative;
}

.panel {
  background-color: white;
  padding: 15px;
  width: 400px;
}
.separator {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ccc;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
}

.separator:not(:empty)::before {
  margin-right: 0.5em;
}

.separator:not(:empty)::after {
  margin-left: 0.5em;
}

.centered-button {
  display: grid;
  justify-content: center;
  align-content: center;
}

.centered-button > button {
  width: 100%;
}

.centered-button {
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
}

.microsoftLogin {
  background: url('./MSSymbol.svg') no-repeat;
  background-position-x: 8px;
  background-position-y: 50%;
  color: #5e5e5e;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
}

.lemonsoftLogin {
  background: url('./lemonsoft-favicon.ico') no-repeat;
  background-position-x: 8px;
  background-position-y: 50%;
  color: #5e5e5e;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 100%;
  margin-top: 15px;
}
