@use './colors' as *;

@use "react-widgets/lib/scss/react-widgets.scss" with (
  $state-bg-select: $primary,
  $state-border-focus: $primary
);

@import './burger-side.scss';
@import './flexmonster';
@import './react-resizable';
@import './dark';
@import './glyphicon.scss';

html {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

html,
body,
#app,
#app > div {
  height: 100%;
}

[data-reactroot] {
  display: flex;
  flex-direction: column;
}

$gray-base: $neutral;

// Body
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-size-base: 14px;
$body-color: $gray-base;
$enable-shadows: true;
$headings-line-height: 1.1;
$headings-color: $gray-base;

// Links
$link-hover-decoration: none;

// Forms
$input-color: $gray-base;
$input-border: $input-border;
$dropdown-border: $primary;
$dropdown-divider-bg: $primary;
$dropdown-link-color: $primary;
$dropdown-link-hover-color: darken($primary, 15%);
$dropdown-spacer: 0px;
$form-label-font-weight: 700;
$form-label-margin-bottom: 5px;
$input-placeholder-color: $input-placeholder;
$input-border-radius: 0px;
$input-group-addon-padding-y: 0px;
$input-focus-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px $primary-hover;
$input-focus-border-color: $primary;
$dropdown-border-width: 0px;
$dropdown-border-radius: 0px;
$dropdown-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);

// Navbars
$navbar-dark-brand-color: rgb(255, 255, 255);
$navbar-dark-brand-hover-color: rgb(255, 255, 255);
$navbar-dark-color: rgb(255, 255, 255);
$navbar-dropdown-background-color: #fff;
$navbar-dropdown-hover-background-color: #f5f5f5;
$nav-link-padding-y: 10px;
$nav-link-padding-x: 15px;
$navbar-nav-link-padding-x: 15px;
$nav-tabs-link-hover-border-color: rgb(233, 236, 239) rgb(233, 236, 239)
  rgb(222 226 230 / 0%) rgb(233, 236, 239);

// Modal
$modal-content-border-color: $purple-dark;
$modal-backdrop-opacity: 0.2;
$modal-header-border-color: rgb(255, 255, 255);
$modal-footer-border-color: rgb(255, 255, 255);
$modal-content-border-radius: 0px;

// Table
$table-group-separator-color: #dddddd;

// Button variables
$btn-white-space: nowrap;
$btn-focus-box-shadow: inset 0 3px 8px rgba(black, 0.2);
$btn-focus-width: 0px;
$btn-border-radius: 2px;
$border-radius-sm: 0px;
$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 10px;

// Breadcrumb
$breadcrumb-divider: '>';
$breadcrumb-font-size: 20px;
$breadcrumb-active-color: $gray-base;
$link-decoration: none;
$breadcrumb-bg: rgb(255, 255, 255);

// Modals
$modal-lg: 900px;

@import './bootstrap/scss/bootstrap';

// Custom utility classes
.hidden {
  display: none;
}

// react-widgets customizations
.rw-widget-container {
  border-radius: 0px;
}

.rw-widget-picker {
  height: 2.5em;
}

.rw-multiselect-tag {
  vertical-align: bottom;
}
.rw-multiselect > .rw-widget-picker {
  height: 100%;
}

// Can't find bootstrap or react-widget variable to set this
input[type='checkbox'],
input[type='radio'] {
  accent-color: $primary;
}

//There is no margin top customizations???
.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

// Navbar
.navbar-dark .nav-link.active {
  background-color: $primary;
}

//Form-group is LEGACY BS 5 uses only input group
.radio-container,
.form-group {
  margin-bottom: 15px;
}

//<label> elements don't care about BS so we have to manually style them. BS uses <FormLabel>
label,
.label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

//Button group
// .btn-group {
//   flex-wrap: wrap;
// }
.btn-group > .btn {
  flex-grow: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

@import './buttonCustomizations.scss';

// Pop-Over

.bs-popover-start,
.bs-popover-end,
.bs-popover-top,
.bs-popover-bottom {
  padding: 9px 14px;
}

#fm-pivot-view {
  min-height: 0px !important;
}
